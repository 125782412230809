import * as React from 'react';
import { ResourceListConfiguration, ResourceListLayoutType, ResourceListResource } from './interfaces/ResourceList';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import { Locales } from 'common/types/locale';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { localeSelector } from 'common/selectors/localeSelector';
import { LazyImageProps, LazyImage } from 'styleguide/components/LazyImage';
import OddlyWrapper, { OddlyWrapperBackground } from '../Oddlygood/OddlyWrapper';
import OddlyWrapperInner from '../Oddlygood/OddlyWrapperInner';
import OddlySubTitle, { OddlySubTitleType } from '../Oddlygood/OddlySubTitle';

const RESOURCE_IMAGE_ASPECT_RATIO = 180 / 280;

const ResourceListWrapper = styled.article`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;

	${media.desktop`
    margin: ${WIDGET_SPACING_LARGE}px auto;
  `};
`;

const Header = styled.header`
	margin: 0 ${({ theme }) => theme.grid.gutterInPx()} ${({ theme }) => theme.grid.gutterInPx(4)};

	${media.tablet`
		margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(4)};
	`};
`;

const OddlyResourceListWrapper = styled.article`
	margin: 0px auto;
	padding-top: ${WIDGET_SPACING_MEDIUM}px;
	padding-bottom: ${WIDGET_SPACING_MEDIUM}px;

	${media.tablet`
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	`};

	${media.desktop`
    margin: 0px auto;
		max-width: 1200px;
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
		padding-top: ${WIDGET_SPACING_LARGE}px;
		padding-bottom: ${WIDGET_SPACING_LARGE}px;
  `};
`;

const OddlyHeader = styled.header`
	margin: 0 ${({ theme }) => theme.grid.gutterInPx()} ${({ theme }) => theme.grid.gutterInPx(4)};

	${media.phone`
		padding-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	`};
	${media.tablet`
		padding-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	`};
	${media.desktop`
		position: relative;
		top: -35px;
	`};
`;

interface TitleProps {
	hasIcon?: boolean;
}

const Title = styled.h2<TitleProps>`
	${({ theme }) => theme.typography.heading};
	font-size: 32px;
	line-height: ${({ theme }) => theme.grid.gutterInPx(4)};
	color: ${({ theme }) => theme.colors.brandPrimary};
	text-transform: uppercase;
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx()};
	display: inline-block;

	${media.tablet`
		font-size: 48px;
		line-height: ${({ theme }) => theme.grid.gutterInPx(6)};
	`};

	${props => {
		if (props.hasIcon) {
			return css`
				display: inline-flex;
				padding-left: 54px;
				position: relative;
				align-items: center;
				left: -15px;

				${media.tablet`
					padding-left: 80px;
				`};

				img {
					position: absolute;
					left: 0;
					top: 50%;
					width: 54px;
					height: 54px;
					transform: translateY(-50%);

					${media.tablet`
						width: 80px;
						height: 80px;
					`};
				}
			`;
		}

		return null;
	}};
`;

const SubTitle = styled.span`
	display: inline-block;
	font-size: 20px;
	line-height: 28px;

	${media.tablet`
		margin-left: ${({ theme }) => theme.grid.gutterInPx(6)};
	`};
`;

const Image = styled.div`
	width: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: ${RESOURCE_IMAGE_ASPECT_RATIO * 100}%;
`;

const Resources = styled.ul`
	margin: 0 ${({ theme }) => theme.grid.gutterInPx()};
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;

	${media.phone560`
		margin: ${({ theme }) => theme.grid.gutter * 1.5 * -1}px;
	`};

	@supports (display: grid) {
		margin: 0 ${({ theme }) => theme.grid.gutterInPx()};

		${media.phone560`
			margin: 0;
			display: grid;
			grid-gap: ${({ theme }) => theme.grid.gutterInPx(3)};
			grid-template-columns: repeat(2, 1fr);
		`};

		${media.tablet`
			grid-template-columns: repeat(3, 1fr);
		`};

		${media.desktop`
			grid-template-columns: repeat(4, 1fr);
		`};
	}
`;

const Resource = styled.li`
	display: block;
	width: 100%;

	&:nth-child(n + 2) {
		display: none;
	}

	${media.phone560`
		flex: 1 0 auto;
		margin: ${({ theme }) => theme.grid.gutterInPx(1.5)};
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(3)});

		&:nth-child(n + 2) {
			display: block;
		}
		&:nth-child(n + 3) {
			display: none;
		}
	`};

	${media.tablet`
		width: calc(100% / 3 - ${({ theme }) => theme.grid.gutterInPx(3)});

		&:nth-child(n + 3) {
			display: block;
		}
	`};

	${media.desktop`
		width: calc(25% - ${({ theme }) => theme.grid.gutterInPx(3)});
	`};

	& + & {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};

		${media.phone560`
			margin-top: 0;
		`};
	}

	@supports (display: grid) {
		${media.phone560`
			flex: none;
			width: auto;
			margin: 0;
		`};
	}
`;

const PublicationTime = styled.time`
	display: block;
	font-size: 13px;
	line-height: 20px;
	margin: ${({ theme }) => theme.grid.gutterInPx()} 0;

	${media.tablet`
		margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0;
	`};
`;

const Link = styled.a`
	${({ theme }) => theme.typography.heading};
	font-size: 14px;
	line-height: 16px;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.brandPrimary};
	display: block;
`;

const MoreResources = styled.p`
	margin: ${({ theme }) => theme.grid.gutterInPx(6)} 0 0;
	text-align: center;

	a {
		display: inline-flex;
	}
`;

interface ResourceListState {
	resourceListIcon: React.FC | React.ComponentClass | null;
}

type Props = WidgetCommonProps & ResourceListConfiguration;

class ResourceList extends React.Component<Props & StateProps, ResourceListState> {
	public state: ResourceListState = {
		resourceListIcon: null,
	};

	public componentDidMount() {
		this.loadIcon();
	}

	public render() {
		const { widgetId, title, icon, subTitle, seeMoreText, seeMoreUrl, resources, layoutName } = this.props;
		const { resourceListIcon } = this.state;

		if (resources.length < 1) {
			return null;
		}

		let Icon: any = 'span';
		if (resourceListIcon) {
			Icon = resourceListIcon;
		}

		const defaultLayout = () => (
			<ResourceListWrapper id={widgetId}>
				<Header>
					<Title hasIcon={!!resourceListIcon}>
						{icon && <Icon />}
						{title}
					</Title>
					<SubTitle>{subTitle}</SubTitle>
				</Header>
				<Resources>{resources.map(resource => this.renderResource(resource))}</Resources>
				{seeMoreText && seeMoreUrl && (
					<MoreResources>
						<OutlinedButtonLink href={seeMoreUrl}>{seeMoreText}</OutlinedButtonLink>
					</MoreResources>
				)}
			</ResourceListWrapper>
		);

		const oddlygoodLayout = () => (
			<OddlyWrapper background={OddlyWrapperBackground.Secondary}>
				<OddlyWrapperInner background={OddlyWrapperBackground.Secondary} desktopPadding={15} tabletPadding={15}>
					<OddlyResourceListWrapper id={widgetId}>
						<OddlyHeader>
							{title && <OddlySubTitle title={title} type={OddlySubTitleType.Extra1} />}
							<SubTitle>{subTitle}</SubTitle>
						</OddlyHeader>
						<Resources>{resources.map(resource => this.renderResource(resource))}</Resources>
						{seeMoreText && seeMoreUrl && (
							<MoreResources>
								<OutlinedButtonLink buttonStyle={'secondary'} href={seeMoreUrl}>
									{seeMoreText}
								</OutlinedButtonLink>
							</MoreResources>
						)}
					</OddlyResourceListWrapper>
				</OddlyWrapperInner>
			</OddlyWrapper>
		);

		switch (layoutName) {
			default:
			case ResourceListLayoutType.default:
				return defaultLayout();
			case ResourceListLayoutType.oddlygood:
				return oddlygoodLayout();
		}
	}

	private renderResource({
		url,
		title: resourceTitle,
		publicationTime,
		publicationTimeText,
		imageUrl,
	}: ResourceListResource) {
		const renderImage = ({ src }: LazyImageProps) => {
			const styles = {
				backgroundImage: `url(${imageUrl}/355x234-static-highlight)`,
			};
			return <Image style={styles} title={resourceTitle} />;
		};
		return (
			<Resource key={url}>
				<LazyImage src={`${imageUrl}/355x234-static-highlight`} render={renderImage} />
				<PublicationTime dateTime={publicationTime}>{publicationTimeText}</PublicationTime>
				<Link href={url}>{resourceTitle}</Link>
			</Resource>
		);
	}

	private async loadIcon() {
		const { icon } = this.props;
		if (icon) {
			const styleGuide = await import(/* webpackMode: "eager" */ 'styleguide/components/Icons');
			if (styleGuide[icon]) {
				this.setState({ resourceListIcon: styleGuide[icon] });
			}
		}
	}
}

interface StateProps {
	locale: Locales;
}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = state => {
	const locale = localeSelector(state);
	return {
		locale,
	};
};

export default connect(mapStateToProps)(ResourceList);
