import * as React from 'react';
import { MainTopicHighlightConfiguration, MainTopicHighlightItem } from './interfaces/MainTopicHighlight';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import BodyText from 'styleguide/components/Typography/BodyText/BodyText';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import { LazyImage, LazyImageProps } from 'styleguide/components/LazyImage';

const IMAGE_ASPECT_RATIO = 270 / 580;

const MainTopicHighlightWrapper = styled.article`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;

	${media.desktop`
    margin: ${WIDGET_SPACING_LARGE}px auto;
  `};
`;

const ListWrapper = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;

	${media.tablet`
		display: flex;
		flex-flow: row wrap;
		margin: 0 -${({ theme }) => theme.grid.gutter}px;
		justify-content: space-between;
		align-content: flex-start;
	`};
`;

const ListItem = styled.li`
	max-width: 580px;

	${media.tablet`
		margin-left: ${({ theme }) => theme.grid.gutter}px;
		margin-right: ${({ theme }) => theme.grid.gutter}px;
		flex: 0 1 calc(50% - 24px);
		width: calc(50% - 24px);
	`};
`;

const Image = styled.div`
	width: 100vw;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: ${IMAGE_ASPECT_RATIO * 100}%;
	margin: 0 ${({ theme }) => theme.grid.gutterInPx(-1)};

	${media.phone`
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(2 * -1)};
	`};

	${media.phone560`
		width: 100%;
		margin: 0;
	`};
`;

const ContentWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.bodyBackground};
	position: relative;
	top: -${({ theme }) => theme.grid.gutterInPx(5)};
	padding: 4px ${({ theme }) => theme.grid.gutterInPx(3)} ${({ theme }) => theme.grid.gutterInPx(3)};

	${media.tablet`
		margin-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		margin-right: ${({ theme }) => theme.grid.gutterInPx(3)};
	`};

	> p {
		font-size: 14px;
		line-height: 1.43;

		${media.desktop`
			font-size: 20px;
		`};
	}

	> a:not(.header-link) {
		display: inline-flex;
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(3)};
	}
`;

const HeaderLink = styled.a`
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}

	> h2 {
		font-size: 16px;
		line-height: 1.25;
		text-transform: none;

		${media.desktop`
			font-size: 32px;
		`};
	}
`;

const LinkContents = styled.span``;

const MainTopicHighlight: React.FC<WidgetCommonProps & MainTopicHighlightConfiguration> = ({ widgetId, items }) => {
	if (items.length < 1) {
		return null;
	}

	const renderItem = ({ imageUrl, linkText, title, text, linkUrl }: MainTopicHighlightItem, idx: number) => {
		const renderImage = ({ src }: LazyImageProps) => {
			const styles = {
				backgroundImage: `url(${src})`,
			};
			return <Image style={styles} title={`${linkText} -kuva`} />;
		};

		return (
			<ListItem key={idx}>
				<LazyImage src={`${imageUrl}/580x270-main-top-highlight`} render={renderImage} />
				<ContentWrapper>
					<HeaderLink className="header-link" href={linkUrl}>
						<MediumHeader>{title}</MediumHeader>
					</HeaderLink>
					<BodyText>{text}</BodyText>
					{linkUrl && linkText && (
						<OutlinedButtonLink href={linkUrl}>
							<LinkContents>{linkText}</LinkContents>
						</OutlinedButtonLink>
					)}
				</ContentWrapper>
			</ListItem>
		);
	};

	return (
		<MainTopicHighlightWrapper id={widgetId}>
			<ListWrapper>{items.map((item, idx) => renderItem(item, idx))}</ListWrapper>
		</MainTopicHighlightWrapper>
	);
};

export default MainTopicHighlight;
