import { WidgetConfiguration } from './interfaces';

export enum HeroBannerLayoutType {
	default = '',
	oddlygood = 'oddlygood',
}

export interface HeroBannerConfiguration {
	title: string;
	text: string;
	linkUrl?: string;
	linkText?: string;
	imageUrl: string;
	layoutName?: HeroBannerLayoutType;
}

export interface HeroBannerWidget extends WidgetConfiguration {
	widgetName: 'HeroBannerFrodget';
	configuration: HeroBannerConfiguration;
}
