import * as React from 'react';
import styled, { css } from 'styled-components';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import ButtonLink from 'styleguide/components/Buttons/Button/ButtonLink';
import { WidgetCommonProps } from './interfaces/interfaces';
import { HeroBannerConfiguration, HeroBannerLayoutType } from './interfaces/HeroBanner';
import { getScrollbarWidth } from 'utils/document';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { ContentWrapperProps } from '../General';
import { NavigationLayoutType } from '../Navigation/reducers';
import { convertLineBreaks } from 'utils/strings';
import OddlyTitle from '../Oddlygood/OddlyTitle';
import OddlyWrapper from '../Oddlygood/OddlyWrapper';
import OddlyWrapperInner from '../Oddlygood/OddlyWrapperInner';

const MAX_BANNER_WIDTH = 1600;

const HeroBannerWrapper = styled.article`
	width: 100%;

	${media.tablet`
    display: flex;
    position: relative;
  `};

	${media.desktop1200`
    width: calc(100vw - ${getScrollbarWidth()}px);
    margin: 0 calc(50% - 50vw + ${Math.floor(getScrollbarWidth() / 2)}px);
  `};

	${media.desktop1600`
		max-width: ${MAX_BANNER_WIDTH - getScrollbarWidth()}px;		
		margin: 0 calc(50% - ${(MAX_BANNER_WIDTH - getScrollbarWidth()) / 2}px);
  `};

	@media (min-height: ${mediaBreakpoints.desktop1100}px) and (min-width: ${mediaBreakpoints.desktop1100}px) {
		height: 704px;
	}
`;

const ImageWrapper = styled.div`
	display: none;

	${media.tablet`
    display: block;
		width: 50%;
    min-height: 540px;
		flex: 1 1 50%;
    margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
		position: relative;
  `};

	${media.desktop`
		min-height: 640px;
	`};

	${media.desktop1200`
		max-height: 704px;
	`};
`;

const MobileImageWrapper = styled.div`
	width: 100%;
	height: calc(100vw - ${({ theme }) => theme.grid.gutterInPx(3)});
	margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
	position: relative;
	max-height: 248px;

	${media.phone560`
    max-height: 350px;
  `};

	${media.tablet`
    display: none;
  `};
`;

const BackgroundImage = styled.div`
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
	position: absolute;

	${media.tablet`
		width: calc(50vw - ${({ theme }) => theme.grid.gutterInPx()});
	`};

	${media.desktop`
		width: calc(50vw - ${({ theme }) => theme.grid.gutterInPx(2)});
	`};
`;

const BannerContentWrapper = styled.div`
	font-size: 20px;
	line-height: 28px;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
    font-size: 24px;
    line-height: 30px;
		padding: 0;
		width: 50%;
		flex: 1 1 50%;
  `};

	${media.desktop`
		font-size: 28px;
    line-height: 34px;
	`};
`;

const ContentPageWrapper = styled.div<ContentWrapperProps>`
	width: 100%;
	max-width: ${mediaBreakpoints.desktop1200}px;

	${media.desktop1100<ContentWrapperProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: flex-start;
		padding: 0 ${({ theme }) => theme.grid.gutterInPx()};
  `};
`;

const Content = styled.div`
	width: 100%;
	${props => css`
		padding: ${props.theme.grid.gutter * 3}px 0 ${props.theme.grid.gutterInPx(3)};
	`};

	${media.tablet`
		padding-top: ${props => props.theme.grid.gutter * 4}px;
		padding-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		padding-right: ${({ theme }) => theme.grid.gutterInPx(1.5)};
  `};

	${media.desktop900`
		padding-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		padding-right: ${({ theme }) => theme.grid.gutterInPx(4)};
	`};

	${media.desktop`
		padding-left: ${({ theme }) => theme.grid.gutterInPx(5)};
		padding-right: ${({ theme }) => theme.grid.gutterInPx(5)};
	`};

	${media.desktop1100`
		width: 50%;
		padding-top: 0;
    max-width: 540px;
  `};

	@media (max-height: ${mediaBreakpoints.height600}px) {
		max-width: 520px;
	}

	.hero-banner-link {
		display: inline-flex;
	}
`;

const Title = styled.h1`
	${({ theme }) => theme.typography.heading};
	font-size: 34px;
	line-height: 1;
	color: ${({ theme }) => theme.colors.heading};
	margin: 0;

	${media.phone560`
		font-size: 44px;
    letter-spacing: -0.8px;
		margin-bottom: ${({ theme }) => theme.grid.gutterInPx(4)};
	`};

	${media.desktop`
  	font-size: 60px;
	`};

	@media (max-height: ${mediaBreakpoints.height680}px) {
		font-size: 44px;
	}
`;

const LeadText = styled.p`
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0 ${({ theme }) => theme.grid.gutterInPx(3)} 0;

	${media.phone560`
		margin: ${({ theme }) => theme.grid.gutterInPx(3)} 0;
	`};

	${media.tablet`
	  margin: ${({ theme }) => theme.grid.gutterInPx(4)} 0;
  `};

	@media (max-height: ${mediaBreakpoints.height680}px) {
		font-size: 22px;
		line-height: 28px;
	}
`;

interface LinkWrapperProps {
	centered?: boolean;
}

const LinkWrapper = styled.p<LinkWrapperProps>`
	margin: 0;
	text-align: center;

	${media.tablet<LinkWrapperProps>`
		text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  `};
`;

// =======================================
// ODDLYGOOD STYLES
// =======================================
const OddlygoodContentWrapper = styled.div`
	font-size: 16px;
	line-height: 20px;
	padding: 0 0; /* ${({ theme }) => theme.grid.gutterInPx(2)}; */

	${media.tablet`
    font-size: 18px;
    line-height: 21px;
		padding: 0;
		flex: 1 1 calc(50% - ${({ theme }) => theme.grid.gutterInPx(1)});
  `};

	${media.desktop`
		font-size: 21px;
    line-height: 30px;
	`};
`;

const OddlygoodTextWrapper = styled.div`
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
		padding: 0;
  `};

	${media.desktop`
		padding: 0;
	`};
`;

const OddlygoodContentPageWrapper = styled.div<ContentWrapperProps>`
	width: 100%;
	max-width: ${mediaBreakpoints.desktop1200}px;
	${media.desktop1200`
		margin-left: calc(50vw - 600px);
	`};
`;

const OddlygoodContent = styled.div`
	width: 100%;
	${props => css`
		padding: ${props.theme.grid.gutter * 3}px 0 ${props.theme.grid.gutterInPx(3)};
	`};

	${media.tablet`
		padding-top: ${props => props.theme.grid.gutter * 4}px;
		padding-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		padding-right: ${({ theme }) => theme.grid.gutterInPx(1.5)};
  `};

	${media.desktop900`
		padding-left: ${({ theme }) => theme.grid.gutterInPx(3)};
		padding-right: ${({ theme }) => theme.grid.gutterInPx(4)};
	`};

	${media.desktop`
		padding-left: ${({ theme }) => theme.grid.gutterInPx(5)};
		padding-right: ${({ theme }) => theme.grid.gutterInPx(5)};
	`};

	${media.desktop1100`
		padding-top: 20px;
    max-width: 540px;
  `};

	@media (max-height: ${mediaBreakpoints.height600}px) {
		max-width: 520px;
	}

	.hero-banner-link {
		display: inline-flex;
	}
`;

const OddlyLeadText = styled.p`
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} 0 ${({ theme }) => theme.grid.gutterInPx(3)} 0;

	${media.phone560`
		margin: ${({ theme }) => theme.grid.gutterInPx(3)} 0;
	`};

	${media.tablet`
	  margin: ${({ theme }) => theme.grid.gutterInPx(4)} 0;
  `};
`;

const OddlyImageWrapper = styled(ImageWrapper)`
	width: auto;
	margin-left: 0px;
	flex-basis: calc(50% - ${({ theme }) => theme.grid.gutterInPx(1)});
`;

const OddlyHeroBannerWrapper = styled(HeroBannerWrapper)`
	column-gap: ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.desktop1200`
    width: 100%;
    margin: 0;
  `};

	${media.desktop1600`
		max-width: none;
  `};
`;

const OddlyBackgroundImage = styled(BackgroundImage)`
	max-width: 100%;
	${media.tablet`
		width: 100%;
	`};
`;

// =======================================
// HEROBANNER COMPONENT
// =======================================
const HeroBanner: React.FunctionComponent<HeroBannerStateProps & WidgetCommonProps & HeroBannerConfiguration> = ({
	title,
	text,
	imageUrl,
	linkUrl,
	linkText,
	layoutName,
}) => {
	const defaultLayout = () => (
		<HeroBannerWrapper>
			<BannerContentWrapper>
				<ContentPageWrapper>
					<Content>
						<Title>{title}</Title>
						<MobileImageWrapper>
							<BackgroundImage style={{ backgroundImage: `url(${imageUrl}/495x350-herobanner-mobile)` }} />
						</MobileImageWrapper>
						<LeadText dangerouslySetInnerHTML={{ __html: convertLineBreaks(text) }} />
						{linkUrl && linkText && (
							<LinkWrapper>
								<ButtonLink href={linkUrl} className="hero-banner-link">
									{linkText}
								</ButtonLink>
							</LinkWrapper>
						)}
					</Content>
				</ContentPageWrapper>
			</BannerContentWrapper>
			<ImageWrapper>
				<BackgroundImage style={{ backgroundImage: `url(${imageUrl}/1420x1408-herobanner-desktop)` }} />
			</ImageWrapper>
		</HeroBannerWrapper>
	);

	const oddlygoodLayout = () => (
		<OddlyWrapper>
			<OddlyWrapperInner>
				<OddlyHeroBannerWrapper>
					<OddlygoodContentWrapper>
						<OddlygoodContentPageWrapper>
							<OddlygoodContent>
								<OddlyTitle title={title} />
								<MobileImageWrapper>
									<BackgroundImage style={{ backgroundImage: `url(${imageUrl}/495x350-herobanner-mobile)` }} />
								</MobileImageWrapper>
								<OddlygoodTextWrapper>
									<OddlyLeadText dangerouslySetInnerHTML={{ __html: convertLineBreaks(text) }} />
									{linkUrl && linkText && (
										<LinkWrapper centered={true}>
											<ButtonLink buttonStyle={'secondary'} href={linkUrl} className="hero-banner-link">
												{linkText}
											</ButtonLink>
										</LinkWrapper>
									)}
								</OddlygoodTextWrapper>
							</OddlygoodContent>
						</OddlygoodContentPageWrapper>
					</OddlygoodContentWrapper>
					<OddlyImageWrapper>
						<OddlyBackgroundImage style={{ backgroundImage: `url(${imageUrl}/1420x1408-herobanner-desktop)` }} />
					</OddlyImageWrapper>
				</OddlyHeroBannerWrapper>
			</OddlyWrapperInner>
		</OddlyWrapper>
	);

	switch (layoutName) {
		default:
		case HeroBannerLayoutType.default:
			return defaultLayout();
		case HeroBannerLayoutType.oddlygood:
			return oddlygoodLayout();
	}
};

interface HeroBannerStateProps {
	navigationLayout: NavigationLayoutType;
}

const mapStateToProps: MapStateToProps<HeroBannerStateProps, WidgetCommonProps, State> = ({
	navigation,
}: State): HeroBannerStateProps => {
	return {
		navigationLayout: navigation.layout,
	};
};

export default connect<HeroBannerStateProps, {}, WidgetCommonProps, State>(mapStateToProps)(HeroBanner);
