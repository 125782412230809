import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { themeColors } from 'styleguide/themes/oddlygood';

const Title = styled.h1`
	${({ theme }) => theme.typography.heading};
	font-size: 34px;
	line-height: 1;
	color: ${({ theme }) => theme.colors.navigationText};
	margin: 0;

	${media.phone560`
  font-size: 44px;
  letter-spacing: -0.8px;
  margin-bottom: ${({ theme }) => theme.grid.gutterInPx(4)};
`};

	${media.desktop`
  font-size: 60px;
`};
`;

const Title3 = styled.h1`
	${({ theme }) => theme.typography.heading};
	font-size: 34px;
	line-height: 1;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	margin: 0;

	& span {
		margin: 5px;
	}
`;

const Title21 = styled.span`
	font-size: 66px;
	${media.phone560`
  font-size: 84px;
`};
	${media.desktop`
  font-size: 115px;
`};
	${media.desktop1100`
  font-size: 140px;
`};
	margin: 5px 10px 0px 0px;
	padding: 5px 25px 5px 25px;
	background-color: ${themeColors.blockPink};
	color: ${themeColors.white};
`;

const Title22 = styled.span`
	font-size: 36px;
	${media.phone560`
  font-size: 46px;
`};
	${media.desktop`
  font-size: 55px;
  margin: 6px;
`};
	${media.desktop1100`
  font-size: 65px;
`};
	padding: 5px 25px 5px 25px;
	transform: rotate(-5deg); /* Equal to rotateZ(45deg) */
	background-color: ${themeColors.blockYellow};
	color: ${themeColors.greenPesto};
`;

const Title31 = styled.span`
	font-size: 36px;
	${media.phone560`
    font-size: 46px;
  `};
	${media.desktop`
    font-size: 55px;
  `};
	${media.desktop1100`
    font-size: 65px;
  `};
	margin: 5px 10px 3px 0px;
	padding: 5px 25px 5px 25px;
	background-color: ${themeColors.blockYellow};
	color: ${themeColors.greenPesto};
`;

const Title32 = styled.span`
	font-size: 36px;
	${media.phone560`
    font-size: 46px;
  `};
	${media.desktop`
    font-size: 55px;
    margin: 6px;
  `};
	${media.desktop1100`
    font-size: 65px;
  `};
	padding: 5px 15px 5px 15px;
	transform: rotate(-5deg); /* Equal to rotateZ(45deg) */
	background-color: ${themeColors.blockPink};
	color: ${themeColors.cream};
`;

const Title33 = styled.span`
	padding: 5px 25px 5px 25px;
	font-size: 66px;
	${media.phone560`
    font-size: 84px;
  `};
	${media.desktop`
    font-size: 115px;
  `};
	${media.desktop1100`
    font-size: 140px;
  `};
	background-color: ${themeColors.blockCyan};
	color: ${themeColors.white};
`;

interface OddlyTitleProps {
	title: string;
}

const OddlyTitle: React.FunctionComponent<OddlyTitleProps> = ({ title }) => {
	if (title.indexOf('^') > -1) {
		const parts = title.split('^');
		if (parts.length === 2) {
			return (
				<Title3>
					<Title21>{parts[0]}</Title21>
					<Title22>{parts[1]}</Title22>
				</Title3>
			);
		} else if (parts.length === 3) {
			return (
				<Title3>
					<Title31>{parts[0]}</Title31>
					<Title32>{parts[1]}</Title32>
					<Title33>{parts[2]}</Title33>
				</Title3>
			);
		}
	}

	return <Title>{title}</Title>;
};

export default OddlyTitle;
