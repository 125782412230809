import { WidgetConfiguration } from './interfaces';

export interface ResourceListResource {
	imageUrl: string;
	title: string;
	publicationTime: string;
	publicationTimeText: string;
	url: string;
}

export enum ResourceListLayoutType {
	default = '',
	oddlygood = 'oddlygood',
}

export interface ResourceListConfiguration {
	icon: string;
	title: string;
	subTitle: string;
	seeMoreText: string;
	seeMoreUrl: string;
	resources: ResourceListResource[];
	layoutName: ResourceListLayoutType;
}

export interface ResourceListWidget extends WidgetConfiguration {
	widgetName: 'ResourceListFrodget';
	configuration: ResourceListConfiguration;
}
